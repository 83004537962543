@import './BaseConfig.scss';

.browserContainer{
    background-color:black;
    max-width: 100%;
    height:auto;
    @include flex-Start;

    h3{
        color:white;
        padding-top: 1rem;
    }
    .searchContainer{
        padding-top:2vh;
        height:auto;
        max-width: 100%;
        color:white;
        @include flex-row;
        position: absolute;
    }

    .searchWrapper{
        max-width:100%;
        width:700px;
        height: auto;
        @include flex-row;
    }
    .searchBar{
        width:230px;
        margin: 0!important;
        height: auto;
       
    }
    .searchButton{
        outline: none;
        background-color: $navHoverBack!important;
        color:$navButtonFont;
        width: 40px!important;
        height: 40px;
        display: inline-block;
        line-height: 30px;
        text-align: center;
        position: relative;
    
        .col{
            margin: 0!important;
            padding: 0!important;
    }   

        i{
            display: block;
            text-align: center;
            line-height: 40px;
        }
    }

  
}

@media screen and (max-width:960px){
    .searchWrapper{
        max-width: 100%!important;
    }
}