@import "./BaseConfig.scss";

.headerWrapper{
    background-image: url('../Assets/Images/BANNER1.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
  

    .boxWrapper{
        display: flex;
        height:auto;
        width:25rem;
        position: absolute; 
        bottom: 0;
        right: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        h3{
            color:white;
            font-size: 3rem;
            font-weight: 400;
            text-align: center;
            width: 75%;
            text-shadow: -2px 2px 0 #000,
           
        }
    .storeWrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        max-width: auto;
        
        .play{
            width:10rem;
            cursor: pointer;
            padding-right:1rem;
        }
        .google{
            width:10rem;
            cursor: pointer;  
        }
    }

    
    }
}