@import './BaseConfig.scss';

.footersContainer{
    height:auto;
    max-width: 100%;
    // @include flex-column;
    background-color: $footerBack;
    .footersWrapper{
        @include flex-column;
        max-width: auto;
        width:100%;
        color: $footerFont;
        
    } 
    .footerWrapper{
        padding-top:2vh;
        @include flex-row;
        color: $footerFont;
        max-width: 100%;
    }
    .copyrightWrapper{
        width: 100%;
        height:auto;
        @include flex-row;

        .fa-copyright{
            width: 100%;
            margin: 0 auto;
            color: $footerFont;
        }
        .copyWrapper{
            @include flex-row;
        }
        p{
            text-align:center;
            color: $footerFont;
        }
    } 
    
    
    
}


.navFooterCont{
    @include borders(blue); 
    max-width: 100%;
}