@import './BaseConfig.scss';
.termsPrivacyContainer {
      
    .termsContainer {
        text-align: center;
        max-width: 100%;
        height: auto;
        h4 {
            color: purple;
        }
        h3 {
            color: purple;
        }
        ul {
            list-style-type: circle;
            text-align: center;
            max-width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        li{
            list-style-type: circle;
            text-align: center;
        }
    }
}
.aboutContainer{
    padding-top: 1rem;
    @include flex-column;
    max-width: 100%;
    height:500px;
  img{
      width: 30%;
      height:auto;
  }

    .headingWrapper{
        height: 100%;
        max-width: 60%;
        text-align:center;
        line-height: 1.5;
        
        h2{
            color:purple;
            font-weight: 800;
            height:auto;
        }
        h5{
            color:purple;
            height:auto;
        }
        p{
            color:purple;
            width: 100%;
            height: auto;
           
        }
   

    .headerBody{
        color:purple;
        height:auto;
        max-width: 80%;
        height:auto;

    }

    .aboutBox{
        background-color: purple;
        height:auto;
        
        .boxFont{
            color:white;
            font-size: 1.5rem;
        }

    }

    .paraAbout{
        text-align: justify;
    }

    }

.rowWrapper{
    max-width: 100%;
    height:auto;
    
}
    .optionsAbout{
        @include flex-row;
        height:auto;
        max-width: 100%;

        .option{
            height:100%;

            h3{
                background-color: purple;
                color:white;
                border:2px solid white;
                height:auto;
                width: 100%;
            }
            p{
                color:purple;
                height:auto;
                text-align: justify;
                width: 100%;
            }
        
        }
         }

        }
   
        .bottomWrapper{
            padding-top: 1.5rem;
            @include flex-column;
            height:auto;

            h3{
                color: white;
                background-color: purple;
                height:auto;
                width: 100%;
            }

            p{
                color:purple;
            }
       
            .loginBtn{
                margin:10vh 0 10vh;
                width:10rem;
                height:2.5rem;
                font-size: 1.5rem;
                background: #DA22FF;  /* fallback for old browsers */
                background: -webkit-linear-gradient(to bottom, #9733EE, #DA22FF);  /* Chrome 10-25, Safari 5.1-6 */
                background: linear-gradient(to bottom, #9733EE, #DA22FF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                color:$navHoverFont;
                border-radius: 50px 50px 50px 50px;
                outline: none;
        
            }
       
       
        }

        .valuesContainer{
            width: 100%;

            h3{
                
            }

            ul{
               text-align: left;
               list-style-position: inside;
              
               li{
                list-style-type: square;
                color:purple
               
               }
            }
        }


@media screen and (max-width: 960px) {

    .optionsAbout{
        @include flex-column;
        max-width:100%;
    }


}