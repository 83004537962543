@import './BaseConfig.scss';

.movieReelContainer{
    // border:2px solid green;
    max-width:100%;
    height:auto;
    // background-color: ;
    background-image: url("../Assets/Images/hiclipart.com.png");
    background-repeat:no-repeat;
    background-size:100% 100%;
    background-color: #232526;  /* fallback for old browsers */
    background-color: -webkit-linear-gradient(to right, #414345, #232526);  /* Chrome 10-25, Safari 5.1-6 */
    background-color: linear-gradient(to right, #414345, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


    .featuredTitle{
        padding-top:1.5rem;
        color:white;
        font-size: 2rem;
        text-align: center;
        // border:2px solid green;
        max-width: 100%;
        height:auto;
     
     
       }

      .galleryButton{
          height:auto;
          padding:2rem;
          max-width: 100%;
        //   border:2px solid red;
          display: flex;
          justify-content: center;
          align-items: center;

          button{
              background-color: purple;
              outline:none;
              height:3rem;
              width:10rem;
              line-height: 1;
              font-size:1.3rem;
              color:white;
              display:block;
              border-radius: 50px;
          }
      }
}