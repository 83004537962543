//Text
@mixin verbage($color, $fontsize, $fontwidth){
    color:$color;
    font-size: $fontsize;
    $font-width:$fontwidth;
    text-align:center;
}
//Flex-box
@mixin flex-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
@mixin flex-column {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
//Borders
@mixin border-top($color, $size, $type){
    border-top: $size $type $color;
}
   
@mixin border-bottom($color, $size, $type){
    border-bottom: $size $type $color;
}

//background
@mixin mainBackground ($color) {
    background-color:$color;
}

//colors
$mainTitles:purple;
$paragraph: black;
$navMenu:purple;
$logoFont:purple;
$red:red;
$primaryButton:purple;
$primaryButtonHover:white;

*, *:before, *:after {
    box-sizing: border-box;
    max-width: auto;
    margin: 0;
	padding: 0;
    border: 0;
    height:100%;
    max-width:100%;
   
}

html, body{
    // height: 100%;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @include mainBackground(white);
    color: $paragraph;
    font-size: 16px; 
}