@import './BaseConfig.scss';

.infoContainer {
    height:auto;
    // border:2px solid red;
    @include border-top(8px,solid,purple);
    height:auto;
.optionsAbout{
    padding:2rem;
    @include flex-row;
    height:auto;
    max-width: 100%;

    .option{
        height:100%;
        width:90%;
        h3{
            text-align: center;
            background-color: purple;
            color:white;
            border:2px solid white;
            height:auto;
            width: 100%;
        }
        p{
            color:purple;
            height:auto;
            text-align: justify;
            width: 100%;
        }
    
    }
     }

    }