@import './BaseConfig.scss';

.navbar {
  background-color: transparent;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  z-index: 100;
  box-shadow: none!important;
 
}

.navbar-logo {
  color: purple;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display:flex;
  justify-content: flex-start;
  padding-bottom: .5rem;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
  padding-top: 1.5rem;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-links {
  color: purple;
  text-decoration: none;
  padding: 0.5rem 1rem;
  
}

.nav-links:hover {
  background-color:transparent;
  color:purple;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}
.navButton{
  background-color:$primaryButton;
  outline: none;
  color:white;
  height:50px!important;
  width:110px !important;
  border:none;
}


.navButton:hover{
  background-color:$primaryButton;
  outline: none;
  color:$navButtonFont!important;
  height:50px!important;
  width:110px !important;

}


.headerlogo{
  font-weight:600;
}


.fa-bars {
  color: purple;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    position: absolute;
    top: 110px;
    left: -100%;
    padding-top:1.5vh;
    opacity: 1;
    transition: all 0.5s ease;
    
  }

  .nav-menu.active {
    background: #302b2b;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    width: 100%;
    color:white
    
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color:white;
    
  }

  .nav-links:hover {
    background-color:purple;
    color:white;
    border-radius: 0;
    height:100px
  
  }

  .navButton:active{
    display: block;
    background-color:$primaryButton;
    outline: none;
    color:$navButtonFont!important;
    height:100px!important;
    width:100%!important;
  
  }

  .navbar-logo {
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0%, 0%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 50%);
    font-size: 1.8rem;
    cursor: pointer;
    /* padding-top: 50px; */
  }

  .fa-times {
    color: purple;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: purple;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  
  }

  .nav-links-mobile:hover {
    background: #fff;
    background-color: rgb(241, 195, 42);
    transition: 250ms;
  }


  .navButton{
    height:100px!important;
    width:100%!important;
    outline:none; 
  }
  .navButton:hover{
  
    height:100px!important;
    width:100%!important;
  }
}