@import './BaseConfig.scss';

.videosContainer{
    
    height:auto;
    // border:2px solid red;
    background-color: black;
    display: flex;
    flex-direction: column;
    height:auto;

.videosWrapper{
    height:1000px;
    max-width: 100%;
    border:2px solid blue;
    
    display: flex;
    flex-direction: column;
  
}
.videoTitles{
    margin: 20px 50px 10px;
    color:white;
     
} 
.swiperWrapper{
    border:2px solid purple;
    height:354px;
    max-width:100%;
   
    
}

.video{
    // border:2px solid green;
    height: 100%;
    width:100%;
   
}


#swiperSlide{
    height:324px;
    max-width:100%!important;
    border:2px solid white;
    // .video{
    //     height:200px;
    //     width:600px; 
    // }
}

.movieWords{
color:white
}
}