@import '../../../Styles/BaseConfig.scss';
// CSS for jumbotron

.Inner{
    @include flex-custom(center,center,row);
    max-width: auto;
    margin: auto;
    max-width:100%;
}

.jumbotronContainer{
    @include flex-row;
    max-width: 100%;
   

}

@media(max-width:960px){
    .Inner{
     flex-direction: column;
    }

    .jumbotronContainer{
        flex-direction: column;
    }
}

