@import './BaseConfig.scss';


.paneContainer{
    @include border-bottom(8px,solid ,purple);
    padding: 50px 5%;
    @include border-top(8px,solid,purple);
    height:auto;
}

.pane1{
    @include flex-column;
    height: auto;
    max-width:80%;
    
}
.pane2{
    @include flex-row;
    max-width:80%;
}

.jumboTitle{
    @include verbage(purple, 2rem, 800)
    
}

.jumbosubtitle{

}

.jumboimage{

}

.jumboalt{

}