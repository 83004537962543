//Starter configs for the app//

//colors
$mainTitles:purple;
$paragraph: black;
$navMenu:purple;
$navBackground:white;
$logoFont:purple;
$red:red;
$primaryButton:purple;
$primaryButtonHover:white;
$footerFont:white;
$footerBack:black;
$navButtonFont:white;
$navHoverBack:purple;
$navHoverFont: white;


//borders
@mixin borders ($color) {
    border:2px solid $color;
}

@mixin border-top($color, $size, $type){
 border-top: $size $type $color;
}

@mixin border-bottom($color, $size, $type){
    border-bottom: $size $type $color;
}

@mixin sectionBorder($height,$px,$type,$color) {
    max-width: 100%;
    height:$height;
    border:$px $type $color;
}

//background color
$mainSection:white;
$offSections: purple;
$extraSections:gold;

@mixin mainBackground ($color) {
    background-color:$color;
}

@mixin verbage($color, $fontsize, $fontwidth){
    color:$color;
    font-size: $fontsize;
    $font-width:$fontwidth;
    text-align:center;
}

//flex - specifictions
@mixin flex-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

@mixin flex-column {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@mixin flex-Start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

@mixin flex-custom($align,$justify,$direction) {
    display: flex;
    align-items: $align;
    justify-content: $justify;
    flex-direction: $direction;
}
@mixin verbage($color, $fontsize, $fontwidth){
    color:$color;
    font-size: $fontsize;
    $font-width:$fontwidth;
    text-align:center;
}
//Flex-box
@mixin flex-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
@mixin flex-column {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@mixin navflex($justify,$align){
    display: flex;
    justify-content: $justify;
    align-items: $align;
}
