@import './BaseConfig.scss';

.signupContainer{
    padding:5vh;
    @include flex-column;
    height: auto;
    width:100%;

    .formWrapper{
        @include sectionBorder(auto,1px, solid,rgb(211, 208, 208));
        width:500px;
        @include flex-column;
        -webkit-box-shadow: 0 10px 6px -6px #777;
        -moz-box-shadow: 0 10px 6px -6px #777;
        box-shadow: 0 10px 6px -6px #777;
    }

    .logoForm{
        padding:10vh 0 5vh;
        width:150px;
        height:auto;
    }

    .loginBtn{
        margin:10vh 0 10vh;

        background: #DA22FF;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to bottom, #9733EE, #DA22FF);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to bottom, #9733EE, #DA22FF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        color:$navHoverFont;
        border-radius: 50px 50px 50px 50px;
        outline: none;

    }

    p{
        text-align: center;
    }

}

