@import './BaseConfig.scss';

.contactContainer{
    padding-top: 2rem;
    max-width: 100%;
    height:auto;
    // border:2px solid red;
    @include flex-row;

    .loginBtn{
        margin:10vh 0 10vh;
        width:10rem;
        height:2.5rem;
        font-size: 1.5rem;
        background: #DA22FF;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to bottom, #9733EE, #DA22FF);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to bottom, #9733EE, #DA22FF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        color:$navHoverFont;
        border-radius: 50px 50px 50px 50px;
        outline: none;

    }

}
