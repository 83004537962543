@import './BaseConfig.scss';

.featuredVideoContainer{
   max-width: 100%;
   height:200px;
   
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   overflow: hidden;
  
   .vidoesWrapper{
    padding-top:2rem;
    height:auto;
    max-width:100%;
    
   
  }

  .video{
   
    height:auto;
    max-width:100%;
    
  }
}